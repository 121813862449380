import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { PrimaryButton, Seo } from 'components';
import Header from 'components/Header';
import { useRouter } from 'apis/history';
import styled from 'styled-components';
import { usePageView, useQuizData } from 'utils/hooks';
import { mobile, tablet } from 'styles/breakpoints';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { QuizAnswer } from 'types/quiz';
import { updateLead, updateQuizAnswers } from 'state/user/effects';
import Disclaimer from 'components/Disclaimer';
import { getSequenceRoute } from 'utils/upgrade';
import { handleError } from 'utils/error';
import { config as projectConfig } from '../../../config';
import axios from 'axios';

const Container = styled.div`
  padding: 3rem 4rem;
  background: #f0f0f0;
  height: calc(100vh - 145px);
  @media ${tablet} {
    padding: 1.5rem 1rem 3rem;
    height: calc(100vh - 126px);
  }
`;

const PreviewContainer = styled(Container)`
  background: #fff;
`;

const ContentContainer = styled.div`
  max-width: 28.75rem;
  width: 100%;
  margin: 0 auto;
`;

const PreviewContentContainer = styled(ContentContainer)`
  padding-bottom: 176px;
  min-height: calc(-120px + 100vh);
  @media ${mobile} {
    min-height: calc(-150px + 100vh);
  }
`;

const Title = styled.p`
  color: #000;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.25rem;
  padding-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const PreviewTitle = styled(Title)`
  text-align: center;
`;

const Subtitle = styled.p`
  color: #1c1c28;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4375rem;
  padding-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: normal;
  }
`;

const PreviewSubtitle = styled(Subtitle)`
  text-align: center;
`;

const Button = styled(PrimaryButton)`
  width: 100%;
  max-width: 28.75rem;
  margin: 0 auto;
`;

const ButtonBack = styled(Button)`
  background: #fff;
  margin-top: 0.5rem;
  div {
    color: #ef71a0;
  }
`;

const Wrapper = styled.div`
  padding-top: 0.875rem;
  @media ${tablet} {
    padding-top: 0.5rem;
  }
`;

const WrapperSticky = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 1rem;
  width: 100%;
  border-top: 1px solid #efefef;
  background: #fff;
  @media ${tablet} {
    padding: 1rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media ${tablet} {
    gap: 1rem;
  }
`;

const Label = styled.label`
  color: #4d4d4d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Counter = styled(Label)``;

const Input = styled.input`
  display: flex;
  padding: 0.75rem;
  justify-content: center;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid #4d4d4d;
  background: #fff;
  outline: none;
`;

const Textarea = styled.textarea`
  display: flex;
  padding: 0.75rem;
  justify-content: center;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid #4d4d4d;
  background: #fff;
  resize: none;
  min-height: 5.8125rem;
  outline: none;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Background = styled.div`
  display: flex;
  gap: 1rem;
  width: 21.6rem;
  height: 30.56675rem;
  flex-direction: column;
  align-items: center;
  border-radius: 0.2rem;
  background: #f3ebde;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2.49569rem 0 2.49594rem;
  @media ${mobile} {
    padding: 0 1.55969rem 0 1.56006rem;
    width: 13.5rem;
    height: 19.10419rem;
    border-radius: 0.125rem;
  }
`;

const Text = styled.p`
  color: #000;
  font-family: 'proxima-nova';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3125rem;
  width: 13.875rem;
  @media ${mobile} {
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.875rem;
    width: 9.125rem;
  }
`;

const ResetButton = styled.p`
  color: #ef71a0;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline !important;
  text-align: right;
  cursor: pointer;
`;

const Dedication: FC = () => {
  const { goToSuccess, goToShipping, goToRegister, navigateWithSearchParams } =
    useRouter();
  const [isPreview, setIsPreview] = useState(false);
  const data = useQuizData('dedication');
  const { quiz_answers, code, user, selected_plans, upsell_products } =
    useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const config = useSelector((state: AppState) => state.config);
  const [dedicationText, setDedicationText] = useState('');
  const [fromText, setFromText] = useState('');
  const [textareaHeight, setTextareaHeight] = useState<number>(0);

  const maxLenghtDedication = 400;
  const maxLenghtFrom = 30;

  usePageView();
  useEffect(() => {
    if (data) {
      setDedicationText(quiz_answers?.dedication || data.defaultDedication);
      setFromText(quiz_answers?.fromWho || '');
    }
  }, [data, quiz_answers]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      const newHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = `${newHeight}px`;
      setTextareaHeight(newHeight);
    }
  }, [dedicationText]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const answer: QuizAnswer = {
      dedication: dedicationText.trim(),
      fromWho: fromText.trim(),
    };

    dispatch(updateQuizAnswers(answer));
    setIsPreview(true);
  };

  const renderGiftOrBookWord = (text: string) => {
    const regex = /\${book_or_gift}/;
    const bookPurpose = quiz_answers?.book_purpose === 'gift' ? 'gift' : 'book';
    const replacedString = text?.replace(regex, bookPurpose);
    return replacedString;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const dedicationString = dedicationText as string;
    if (event.key === ' ' && dedicationString.endsWith(' ')) {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleDefaultButtonClick = () => {
    setDedicationText(data?.defaultDedication);
    setFromText('');
  };

  const generateEbookLink = async (isEmpty: boolean) => {
    try {
      const data = await axios.post(projectConfig.GENERATE_EBOOK, {
        client_email: user?.email,
        client_code: code,
        dedication_text_1: isEmpty ? null : dedicationText.trim() || null,
        dedication_text_2: isEmpty ? null : fromText.trim() || null,
      });
      dispatch(updateQuizAnswers({ ebookUrl: data.data }));
      dispatch(
        updateLead(code, {
          quiz_answers: { ...quiz_answers, ebookUrl: data.data },
        }),
      );
    } catch (e) {
      handleError(e);
    }
  };

  const containsEbook = key => key.includes('e-book');

  const handleForwardRoute = async (isEmpty: boolean) => {
    let matchFound = false;
    const checkoutData = config?.checkout;
    checkoutData?.pageOrder.forEach(obj => {
      Object.entries(obj).forEach(([key, arr]) => {
        if (matchFound) return;
        arr.some(item => {
          const match =
            selected_plans?.find(element => element.key === item) ||
            upsell_products?.find(element => element.key === item);
          if (match) {
            switch (key) {
              case 'dedication': {
                break;
              }
              case 'shipping': {
                matchFound = true;
                goToShipping();
                break;
              }
              case 'register': {
                matchFound = true;
                goToRegister();
                break;
              }
              default:
                matchFound = true;
                goToSuccess();
            }
            return true;
          }
        });
      });
    });

    if (selected_plans?.some(obj => containsEbook(obj.key)))
      generateEbookLink(isEmpty);
  };

  return (
    <>
      <Seo />
      <Header hasDivider={true} />
      {isPreview ? (
        <PreviewContainer>
          <PreviewContentContainer>
            <PreviewTitle>{data?.previewTitle}</PreviewTitle>
            <PreviewSubtitle>{data?.previewSubtitle}</PreviewSubtitle>
            <Background>
              <Text>{quiz_answers?.dedication}</Text>
              <Text>{quiz_answers?.fromWho}</Text>
            </Background>
            <WrapperSticky>
              <Button onClick={() => handleForwardRoute(false)}>
                {data?.previewButtonNext}
              </Button>
              <ButtonBack onClick={() => setIsPreview(false)}>
                {data?.previewButtonBack}
              </ButtonBack>
            </WrapperSticky>
          </PreviewContentContainer>
        </PreviewContainer>
      ) : (
        <Container>
          <ContentContainer>
            <Title>{data?.title}</Title>
            <Subtitle>{renderGiftOrBookWord(data?.subtitle)}</Subtitle>
            <Form onSubmit={handleSubmit}>
              <InputContainer>
                <LabelContainer>
                  <Label>{data?.dedicationInputTitle}</Label>
                  <Counter>
                    {dedicationText?.length}/{maxLenghtDedication}
                  </Counter>
                </LabelContainer>
                <Textarea
                  maxLength={maxLenghtDedication}
                  ref={textAreaRef}
                  value={dedicationText}
                  onChange={e => setDedicationText(e.target.value)}
                  onKeyDown={handleKeyDown}
                  style={{ height: textareaHeight }}
                  required
                />
                <ResetButton type="button" onClick={handleDefaultButtonClick}>
                  {data?.buttonDefault}
                </ResetButton>
              </InputContainer>
              <InputContainer>
                <LabelContainer>
                  <Label>{data?.fromInputTitle}</Label>
                  <Counter>
                    {fromText?.length}/{maxLenghtFrom}
                  </Counter>
                </LabelContainer>
                <Input
                  maxLength={maxLenghtFrom}
                  type="text"
                  value={fromText}
                  onChange={e => setFromText(e.target.value)}
                />
              </InputContainer>
              <Wrapper>
                <Button type="submit">{data?.buttonTitle}</Button>
                <ButtonBack
                  type="button"
                  onClick={() => handleForwardRoute(true)}
                >
                  {data?.skipButton}
                </ButtonBack>
              </Wrapper>
            </Form>
          </ContentContainer>
        </Container>
      )}
      <Disclaimer bgHex={data?.bgColor} text={data?.text} />
    </>
  );
};

export default Dedication;
